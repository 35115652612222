import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import authReducer from './authReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import invitationReducer from './invitationReducer';
import userSearchCriteriasReducer from './userSearchCriteriasReducer';
import infoReducer from './infoReducer';
import unreadReducer from './unreadReducer';
import socketReducer from './socketReducer';

import { LOG_OUT } from '../actions/types';

const appReducer = combineReducers({
   connectedUser: authReducer,
   user: userReducer,
   users: usersReducer,
   form: formReducer,
   invitations: invitationReducer,
   userSearchCriterias: userSearchCriteriasReducer,
   info: infoReducer,
   unread: unreadReducer,
   socket: socketReducer
});

// Voir https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const rootReducer = (state, action) => {
   if (action.type === LOG_OUT) {
      state = undefined;
   }
   return appReducer(state, action);
};
export default rootReducer;
// const rootReducer = (state, action) => {
//     if (action.type === FETCH_USERS) {
//         console.log('nettoyage du state');
//         state = {searchCriteriasUser: null};
//     }
//
//     return appReducer(state, action)
// };
//
// export default rootReducer
