import axios from 'axios';
import _ from 'lodash';

import {
   LOG_IN,
   LOG_OUT,
   FETCH_CONNECTED_USER,
   FETCH_USER,
   FETCH_FILTERED_USERS,
   EDIT_USER,
   USER_SEARCH_CLEAN,
   INFO,
   USER_SEARCH_CRITERIAS
} from './types';
import { TOKENKO } from '../helpers/errorKeys';

// AUTHENTIFICATION
/**
 * Connexion par email
 * @param data
 * @returns {Function}
 */
export const logInUserMail = (data) => async (dispatch) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      const response = await axios.post('/auth/signin', data);
      dispatch({ type: LOG_IN, payload: response.data });
      dispatch({ type: INFO, payload: { isFetching: false } });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: error.response.data.message || 'Une erreur est survenue',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

export const logOut = () => async (dispatch) => {
   try {
      await axios.get('/auth/logout');
      dispatch({ type: LOG_OUT });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               "Déconnexion impossible, merci d'utiliser le formulaire de contact pour nous faire part du problème",
            error: true,
            severity: 'error'
         }
      });
   }
};

export const forgotPassword = (email) => async (dispatch) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      await axios.patch('/auth/forgot', { email });
      dispatch({
         type: INFO,
         payload: {
            message:
               "Si l'adresse existe, vous recevrez un mail à l'adresse indiquée",
            severity: 'info',
            isFetching: false
         }
      });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               "Une erreur est survenue, merci d'utiliser le formulaire de contact pour nous faire part du problème",
            severity: 'error',
            isFetching: false
         }
      });
   }
};

export const isValidToken = async (token) => {
   try {
      await axios.get(`/auth/reset/${token}`);
      return true;
   } catch (error) {
      return false;
   }
};

export const updatePassword = (formValues) => async (dispatch) => {
   try {
      await axios.patch('/api/user/password', formValues);
      dispatch({
         type: INFO,
         payload: {
            message: 'Mot de passe changé avec succès',
            error: false,
            severity: 'success',
            isFetching: false
         }
      });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: 'Une erreur est survenue',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

export const resetPassword = (token, formValues) => async (dispatch) => {
   try {
      const response = await axios.patch(`/auth/reset/${token}`, formValues);
      dispatch({
         type: INFO,
         payload: {
            message: 'Mot de passe changé avec succès',
            error: false,
            severity: 'success',
            isFetching: false
         }
      });
      return response.data;
   } catch (error) {
      if (error.response.data.message === TOKENKO) return TOKENKO;
      dispatch({
         type: INFO,
         payload: {
            message: error.response.data.message,
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
      return { error: true };
   }
};

/**
 * Récupération des préférences de notifications
 * @returns {Function}
 */
export const getNotificationsPreferences = () => async (dispatch) => {
   try {
      const response = await axios.get('/api/user/notification');
      return response.data;
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: 'Impossible de récupérer les préférences de notification',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
      return false;
   }
};

/**
 * MAJ des préférences de notifications
 * @param formValues
 * @returns {Function}
 */
export const updateNotificationsPreferences = (formValues) => async (
   dispatch
) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      await axios.post('/api/user/notification', formValues);
      dispatch({
         type: INFO,
         payload: {
            message: 'Mise à jour effectuée',
            severity: 'success',
            error: false,
            isFetching: false
         }
      });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: 'Impossible de mettre à jour les préférences',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

/**
 * Inscription/Ajout d'un utilisateur
 * @param formValues
 * @returns {Function}
 */
export const userSignUp = (formValues) => async (dispatch) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      const response = await axios.post('/auth/signup', formValues);
      if (response.status !== 400 && response.status !== 401)
         dispatch({ type: LOG_IN, payload: response.data });
      dispatch({ type: INFO, payload: { isFetching: false } });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: error.response.data.message || 'Une erreur est survenue',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

export const userDeleteAccount = () => async (dispatch) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      await axios.delete('/api/users');
      dispatch({ type: LOG_OUT });
      dispatch({
         type: INFO,
         payload: {
            message: 'Votre compte a été supprimé',
            severity: 'success',
            isFetching: false
         }
      });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               'Impossible de supprimer votre compte, merci de nous contacter via le formulaire de contact',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

// PROFIL
/**
 * Mise à jour d'un profil
 * @param id
 * @param formValues
 * @returns {Function}
 */
export const editUser = (id, formValues) => async (dispatch) => {
   try {
      if (id !== null) {
         dispatch({ type: INFO, payload: { isFetching: true } });
         const response = await axios.patch(`/api/users`, formValues);
         dispatch({ type: EDIT_USER, payload: response.data });
         dispatch({
            type: INFO,
            payload: {
               message: 'Mise à jour effectuée',
               severity: 'success',
               error: false,
               isFetching: false
            }
         });
      }
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: 'Erreur lors de la mise à jour',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

// UTILISATEURS
/**
 * Lecture de l'utilisateur connecté
 * @returns {Function}
 */
export const fetchConnectedUser = () => async (dispatch) => {
   try {
      dispatch({ type: INFO, payload: { isFetching: true } });
      const response = await axios.get('/api/user/current');
      //On vérifie que l'objet contient bien un utilisateur (req.user semble pollué et pas toujours null)
      if (response.data._id !== undefined) {
         dispatch({ type: FETCH_CONNECTED_USER, payload: response.data });
      }
      dispatch({ type: FETCH_CONNECTED_USER, payload: response.data });
      dispatch({ type: INFO, payload: { isFetching: false } });
      return response.data;
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               'Il y a eu un problème lors de la connexion à votre compte',
            error: true,
            severity: 'error'
         }
      });
   }
};

/**
 * Lecture d'un utilisateur
 * @param id de l'utilisateur
 * @returns {Function}
 */
export const fetchUserById = (id) => async (dispatch) => {
   try {
      const response = await axios.get(`/api/user?userId=${id}`);
      dispatch({ type: FETCH_USER, payload: response.data });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message: 'Impossible de récupérer les infos de cette personne',
            error: true,
            severity: 'error'
         }
      });
   }
};

/**
 * On nettoie les données pour ne pas envoyer des données vides.
 * On utilise le omit de lodash pour retirer un paramètre.
 * @param searchCriterias
 */
const cleanSearchCriterias = (searchCriterias) => {
   try {
      _.forEach(searchCriterias, function (value, key) {
         if (_.isArray(value)) {
            if (
               _.isEmpty(value) ||
               value[0] === 'indifférent' ||
               value[0] === ''
            ) {
               searchCriterias = _.omit(searchCriterias, key);
            }
         }
         if (_.isString(value)) {
            if (_.isEmpty(value)) {
               searchCriterias = _.omit(searchCriterias, key);
            }
         }
         if (value === undefined) {
            searchCriterias = _.omit(searchCriterias, key);
         }
      });
      return searchCriterias;
   } catch (error) {
      throw new Error(error);
   }
};

/**
 * Lecture d'utilisateurs en fonction des critères de recherches
 * @param searchCriterias
 * @returns {Function}
 */
export const fetchFilteredUsers = (searchCriterias) => async (dispatch) => {
   try {
      searchCriterias = cleanSearchCriterias(searchCriterias);
      dispatch({ type: USER_SEARCH_CRITERIAS, payload: searchCriterias });
      dispatch({ type: INFO, payload: { isFetching: true } });
      const response = await axios.post('/api/users', searchCriterias);
      dispatch({ type: FETCH_FILTERED_USERS, payload: response.data });
      dispatch({ type: INFO, payload: { isFetching: false } });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               'Impossible de récupérer la liste des personnes correspondant à ces critères',
            error: true,
            severity: 'error',
            isFetching: false
         }
      });
   }
};

/**
 * Vider le state des utilisateurs que l'on recherche
 * @returns {Function}
 */
export const emptyStateOnUserSearch = () => async (dispatch) => {
   dispatch({ type: USER_SEARCH_CLEAN });
};

/**
 * Appel la route de contact pour nous envoyer un mail
 * @param {Object} formValues nom, email, message
 */
export const sendContactFormData = (formValues) => async (dispatch) => {
   try {
      await axios.post(`/api/contact`, formValues);
      dispatch({
         type: INFO,
         payload: {
            message: 'Nous vous répondrons dans les plus brefs délais',
            severity: 'success',
            isFetching: false
         }
      });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               'Une erreur est survenue, nous allons corriger le problème',
            error: true,
            severity: 'error'
         }
      });
   }
};
