import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

// https://material-ui.com/customization/components/#3-specific-variation-of-a-component
const styles = {
   root: {
      padding: '25px',
      marginBottom: '10px',
      color: '#555555'
   }
};

function CustomPaper(props) {
   const { classes, children, className, ...other } = props;

   return (
      <Paper elevation={0} className={clsx(classes.root, className)} {...other}>
         {children}
      </Paper>
   );
}

CustomPaper.propTypes = {
   children: PropTypes.node,
   classes: PropTypes.object.isRequired,
   className: PropTypes.string
};

export default withStyles(styles)(CustomPaper);
