// Fonctions de validation des champs de nos formulaires

export const required = (value) =>
   value || typeof value === 'number' ? undefined : 'Requis';

export const maxLength = (max) => (value) =>
   value && value.length > max ? `Maximum ${max} caractères` : undefined;

export const maxLength15 = maxLength(15);

const minLength = (min) => (value) =>
   value && value.length < min ? `Au moins ${min} caractères.` : undefined;

export const minLength8 = minLength(8);

export const number = (value) =>
   value && isNaN(Number(value)) ? 'Doit être un nombre' : undefined;

const minValue = (min) => (value) =>
   value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const email = (value) =>
   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Adresse mail invalide'
      : undefined;

export const password = (value) => {
   // String compliqué parce qu'il y a 2 escapes
   // Escape du string (ex : \')
   // Escape du regex (ex: \\\\ = accepter le caractère \)
   const regEx =
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-zÀ-ÖØ-öø-ÿ~,><.;`\\]\\[\\{\\}\\)\\(\\\\!?\\/@\'|#$%^&*+:="\\d\\w-]{8,}$';
   const globalRegex = new RegExp(regEx, 'g');
   if (globalRegex.test(value)) return undefined;
   else
      return 'Au moins 8 caractères, une majuscule, une minuscule et un chiffre';
};

export const tooYoung = (value) =>
   value && value < 13
      ? 'You do not meet the minimum age requirement!'
      : undefined;

export const alphaNumeric = (value) =>
   value && !/^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]+$/i.test(value)
      ? 'Veuillez entrer des caractères alphanumériques'
      : undefined;

export const phoneNumber = (value) =>
   value && !/^(0|[1-9][0-9]{9})$/i.test(value)
      ? 'Invalid phone number, must be 10 digits'
      : undefined;
