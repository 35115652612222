import { USER_SEARCH_CRITERIAS } from '../actions/types';

const userSearchCriteriasReducer = (state = null, action) => {
   switch (action.type) {
      case USER_SEARCH_CRITERIAS:
         return { ...action.payload };
      default:
         return state;
   }
};

export default userSearchCriteriasReducer;
