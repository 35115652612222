import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
//MUI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CustomPaper from './helpers/CustomPaper';
import Box from '@material-ui/core/Box';
import { theme } from '../styles/themeMUI';
import makeStyles from '@material-ui/core/styles/makeStyles';

//MUI-icons
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SmsIcon from '@material-ui/icons/Sms';
import SubjectIcon from '@material-ui/icons/Subject';
import SendIcon from '@material-ui/icons/Send';

//Composants maison
import '../styles/Home.scss';
import { ReactComponent as SimpleIcon } from '../styles/images/simple.svg';
import { ReactComponent as TransparentIcon } from '../styles/images/transparent.svg';
import { ReactComponent as HumainIcon } from '../styles/images/humain.svg';
import { ReactComponent as SalonImage } from '../styles/images/salon-colocataires-lyon.svg';
import { ABOUT, AUTHORIZATION, USERS_LIST } from '../routes';

const useStyles = makeStyles(() => ({
   centerContainer: { textAlign: 'center' },
   fontSize60: { fontSize: 60 },
   imageContainer: {
      width: '100%',
      height: 'auto',
      padding: '25px 0',
      backgroundColor: theme.palette.background.main,
      display: 'flex',
      justifyContent: 'center'
   },
   homeImage: {
      width: '80vh'
   },
   mainTitle: {
      [theme.breakpoints.down('sm')]: {
         fontSize: '2.6rem'
      },
      fontFamily: 'Caveat-Regular',
      fontSize: '3.6rem'
   },
   firstWordTitle: {
      color: theme.palette.secondary.main
   },
   howToStep: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 700,
      padding: '20px',
      borderRadius: '10px'
   },
   howToStepAvatar: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main
   },
   contactBox: {
      backgroundColor: theme.palette.secondary.main,
      padding: '10px 12px 10px 10px',
      borderRadius: '5px',
      maxWidth: '10rem',
      display: 'inline-flex',
      color: theme.palette.primary.contrastText
   },
   messageBox: {
      padding: '10px 12px 10px 10px',
      borderRadius: '5px',
      maxWidth: '10rem',
      display: 'inline-flex'
   },
   messageBox1: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText
   },
   messageBox2: {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.primary.main
   },
   contactBoxIcon: {
      fontSize: '35px'
   },
   horizontalLines: {
      display: 'block'
   },
   horizontalLine: {
      border: `2px solid ${theme.palette.primary.contrastText}`,
      height: '2px',
      borderRadius: '5px',
      width: '7em',
      margin: '5px'
   },
   horizontalBoldLine: {
      border: `6px solid ${theme.palette.primary.contrastText}`,
      height: '2px',
      borderRadius: '5px'
   },
   horizontalShortLine: {
      width: '5em'
   },
   buttonBox: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      padding: '10px 16px',
      borderRadius: '20px',
      fontSize: '1rem'
   },
   buttonBoxIcon: {
      fontSize: '35px',
      color: theme.palette.primary.contrastText,
      position: 'relative',
      left: '-15px',
      top: '-10px'
   },
   howToStepContainerText: {
      padding: '15px !important'
   },
   buttonIconContainer: {
      marginLeft: '15px !important'
   },
   chatBox: {
      padding: '10px'
   },
   chatIcon1: {
      fontSize: '35px',
      color: theme.palette.primary.main
   },
   chatIcon2: {
      fontSize: '35px',
      transform: 'rotateY(-180deg)'
   },
   accountChatIcon1: {
      color: theme.palette.primary.main
   },
   sendChatMessage: {
      fontSize: 'large',
      top: '2px',
      position: 'relative',
      left: '4px'
   },
   iconsColor: {
      color: theme.palette.primary.main
   },
   containerIcons: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '15px',
      height: '70px',
      alignItems: 'center'
   },
   backgroundIcons: {
      backgroundColor: theme.palette.background.main,
      height: '70px',
      width: '70px',
      position: 'absolute',
      borderRadius: '35px'
   },
   icons: {
      width: '45px',
      height: '45px',
      display: 'flex',
      zIndex: 9,
      justifyContent: 'center',
      '& path, & line, & polyline, & circle': {
         stroke: theme.palette.secondary.main
      }
   },
   wrapTextButton: {
      whiteSpace: 'initial !important'
   }
}));

const Home = () => {
   const classes = useStyles();
   const connectedUser = useSelector((state) => state.connectedUser);

   const renderButtonConnectedUser = () => {
      let linkButton, textButton;
      if (connectedUser) {
         linkButton = USERS_LIST;
         textButton = 'Recherchez vos futurs colocs !';
      } else {
         linkButton = AUTHORIZATION;
         textButton = 'Connectez-vous pour rechercher vos futurs colocs !';
      }
      return (
         <Link to={linkButton} className="nav-link">
            <Button
               variant="contained"
               color="secondary"
               size="large"
               startIcon={<SearchIcon />}
               className={classes.wrapTextButton}
            >
               {textButton}
            </Button>
         </Link>
      );
   };

   return (
      <>
         <div className={`${classes.imageContainer} `}>
            <SalonImage className={classes.homeImage} />
         </div>
         <Container maxWidth={'lg'}>
            {/*Mes Colocataires, c'est quoi ?*/}
            <CustomPaper>
               <Typography
                  variant="h1"
                  align="center"
                  color={'primary'}
                  gutterBottom
                  className={classes.mainTitle}
               >
                  <span className={classes.firstWordTitle}>mes</span>
                  colocataires.fr
               </Typography>
               <Typography
                  variant="h2"
                  align="center"
                  color={'primary'}
                  gutterBottom
               >
                  Une plateforme pour trouver vos futurs colocataires.
               </Typography>
               <Typography
                  variant="h3"
                  align="center"
                  color={'primary'}
                  gutterBottom
               >
                  Pour chercher un appart' ensemble ou pour compléter une
                  coloc'.
               </Typography>
               <Grid
                  container
                  spacing={6}
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  className={'containerMarginTop10'}
               >
                  <Grid item sm={4}>
                     <div className={classes.containerIcons}>
                        <div className={classes.backgroundIcons}>&nbsp;</div>
                        <SimpleIcon className={classes.icons} />
                     </div>
                     <Typography
                        color="primary"
                        align="center"
                        variant="h3"
                        gutterBottom
                     >
                        Simple
                     </Typography>
                     <Typography align="center">
                        Renseignez votre profil, trouvez les personnes qui vous
                        correspondent, et contactez les directement.
                     </Typography>
                  </Grid>
                  <Grid item sm={4}>
                     <div className={classes.containerIcons}>
                        <div className={classes.backgroundIcons}>&nbsp;</div>
                        <TransparentIcon className={classes.icons} />
                     </div>
                     <Typography
                        color="primary"
                        align="center"
                        variant="h3"
                        gutterBottom
                     >
                        Transparent
                     </Typography>
                     <Typography align="center">
                        On ne revend pas vos données personnelles. Il n'y a pas
                        de publicité sur notre site.
                     </Typography>
                  </Grid>
                  <Grid item sm={4}>
                     <div className={classes.containerIcons}>
                        <div className={classes.backgroundIcons}>&nbsp;</div>
                        <HumainIcon className={classes.icons} />
                     </div>
                     <Typography
                        color="primary"
                        align="center"
                        variant="h3"
                        gutterBottom
                     >
                        Humain
                     </Typography>
                     <Typography align="center">
                        Nous sommes bénévoles. Ce projet est né de la volonté
                        d'aider les personnes à former un groupe de
                        colocataires.
                     </Typography>
                  </Grid>
               </Grid>
            </CustomPaper>

            <CustomPaper className={classes.centerContainer}>
               {renderButtonConnectedUser()}
            </CustomPaper>

            {/* A propos de cette version */}
            <Typography variant="h2" align="center" color={'primary'}>
               Qui sommes-nous ?
            </Typography>
            <CustomPaper>
               <Grid container spacing={6} justifyContent="center">
                  <Grid item sm={12}>
                     <Typography align="justify" variant="body1" gutterBottom>
                        Nous sommes une association vous proposant un outil
                        gratuit vous permettant de trouver vos futurs
                        colocataires et ce, dans le respect de votre vie privée.
                        Pour en savoir plus sur nous et notre démarche, vous
                        pouvez consulter{' '}
                        <Link to={`${ABOUT}`} className="nav-link">
                           cette page
                        </Link>
                        .
                     </Typography>
                  </Grid>
                  <Grid item sm={6}>
                     <Typography
                        variant="h3"
                        align="center"
                        color={'primary'}
                        gutterBottom
                     >
                        À propos de cette version
                     </Typography>
                     <Typography align="justify">
                        Nous lançons le site à <b>Lyon</b> pour tester sa
                        viabilité. Certains écrans vont évoluer. Nous
                        travaillons actuellement sur :
                     </Typography>
                     <Typography component="ul" gutterBottom>
                        <li>
                           le <b>design</b>
                        </li>
                        <li>
                           l'ergonomie de la <b>recherche</b>
                        </li>
                        <li>
                           les <b>notifications</b> des nouveaux messages reçus
                        </li>
                     </Typography>
                  </Grid>
                  <Grid item sm={6}>
                     <Typography
                        variant="h3"
                        align="center"
                        color={'primary'}
                        gutterBottom
                     >
                        Un site collaboratif
                     </Typography>
                     <Typography align="justify">
                        Nous sommes pour un internet libre. Partant de là, vos
                        propositions sont essentielles pour améliorer cet outil.
                        Vous pouvez accéder au formulaire de contact en bas de
                        page pour nous laisser vos messages. Dans un futur
                        proche, nous allons proposer aux inscrits une nouvelle
                        page pour vous faire <b>participer</b> plus simplement
                        avec un <b>système de vote</b>.
                     </Typography>
                  </Grid>
               </Grid>
            </CustomPaper>

            {/*Mes Colocataires, comment ça marche ?*/}
            <Typography
               variant="h2"
               align="center"
               color={'primary'}
               gutterBottom
            >
               Comment ça marche ?
            </Typography>
            <CustomPaper>
               <Grid container direction="row" justifyContent="flex-start" spacing={6}>
                  <Grid item container sm={6}>
                     <Grid
                        item
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.howToStep}
                     >
                        <Grid item>
                           <Avatar className={classes.howToStepAvatar}>
                              1
                           </Avatar>
                        </Grid>
                        <Grid item>
                           <Typography variant={'h5'}>
                              Je rempli mon profil
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           xs={12}
                           container
                           justifyContent="center"
                           className={classes.howToStepContainerText}
                        >
                           <Box className={classes.contactBox}>
                              <AccountCircleOutlinedIcon
                                 className={classes.contactBoxIcon}
                              />
                              <div className={classes.horizontalLines}>
                                 <div className={classes.horizontalLine} />
                                 <div className={classes.horizontalLine} />
                                 <div
                                    className={`${classes.horizontalLine} ${classes.horizontalShortLine}`}
                                 />
                              </div>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item container sm={6}>
                     <Grid
                        item
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.howToStep}
                     >
                        <Grid item>
                           <Avatar className={classes.howToStepAvatar}>
                              2
                           </Avatar>
                        </Grid>
                        <Grid item>
                           <Typography variant={'h5'}>
                              J'utilise la recherche
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           xs={12}
                           container
                           justifyContent="center"
                           className={`${classes.howToStepContainerText} ${classes.buttonIconContainer}`}
                        >
                           <Box className={classes.buttonBox}>Rechercher</Box>
                           <SearchIcon className={classes.buttonBoxIcon} />
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item container sm={6}>
                     <Grid
                        item
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.howToStep}
                     >
                        <Grid item>
                           <Avatar className={classes.howToStepAvatar}>
                              3
                           </Avatar>
                        </Grid>
                        <Grid item>
                           <Typography variant={'h5'}>
                              J'envoie une demande de contact
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           xs={12}
                           container
                           justifyContent="center"
                           className={`${classes.howToStepContainerText} ${classes.buttonIconContainer}`}
                        >
                           <Box className={classes.buttonBox}>
                              Envoyer un message
                           </Box>
                           <SmsIcon className={classes.buttonBoxIcon} />
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item container sm={6}>
                     <Grid
                        item
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.howToStep}
                     >
                        <Grid item>
                           <Avatar className={classes.howToStepAvatar}>
                              4
                           </Avatar>
                        </Grid>
                        <Grid item>
                           <Typography variant={'h5'}>
                              On discute sur le Chat
                           </Typography>
                        </Grid>
                        <Grid
                           item
                           xs={12}
                           container
                           justifyContent="center"
                           className={`${classes.howToStepContainerText}`}
                        >
                           <Box
                              className={`${classes.contactBox} ${classes.chatBox}`}
                           >
                              <Grid item xs={12} container justifyContent="center">
                                 <Grid
                                    item
                                    container
                                    justifyContent="flex-start"
                                    xs={12}
                                 >
                                    <AccountCircleOutlinedIcon
                                       className={`${classes.contactBoxIcon} ${classes.accountChatIcon1}`}
                                    />
                                    <SubjectIcon
                                       className={classes.chatIcon1}
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    xs={12}
                                 >
                                    <SubjectIcon
                                       className={classes.chatIcon2}
                                    />
                                    <AccountCircleOutlinedIcon
                                       className={`${classes.contactBoxIcon}`}
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    container
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    xs={12}
                                 >
                                    <Grid item xs={10}>
                                       <div
                                          className={classes.horizontalBoldLine}
                                       />
                                    </Grid>
                                    <Grid item xs={2}>
                                       <SendIcon
                                          className={classes.sendChatMessage}
                                       />
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </CustomPaper>
         </Container>
      </>
   );
};

export default Home;
