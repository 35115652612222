import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
   ABOUT,
   ACCOUNT,
   AUTHORIZATION,
   AUTHORIZATION_FORGOTTEN_PASSWORD,
   AUTHORIZATION_RESET_PASSWORD,
   HOME,
   INVITATIONS_LIST,
   PROFILE_EDIT,
   RGPD,
   TERMS,
   USERS_CREATE,
   USERS_LIST,
   USERS_READ
} from './index';
import Home from '../components/Home';

const About = lazy(() => import('../components/About'));
const Terms = lazy(() => import('../components/Terms'));
const Rgpd = lazy(() => import('../components/RGPD'));
const Auth = lazy(() => import('../components/authorization/Authorization'));
const ForgottenPassword = lazy(() =>
   import('../components/authorization/ForgottenPassword')
);
const ResetPassword = lazy(() =>
   import('../components/authorization/ResetPassword')
);
const ProfileEdit = lazy(() => import('../components/profile/ProfileEdit'));
const Users = lazy(() => import('../components/userSearch/UserSearch'));
const UserDetails = lazy(() => import('../components/userDetails/UserDetails'));
const UserCreate = lazy(() => import('../components/authorization/UserSignUp'));
const Invitations = lazy(() => import('../components/invitations/Invitations'));
const Account = lazy(() => import('../components/account/Account'));
const UnknownRoute = lazy(() => import('../components/UnknownRoute'));

const Routes = () => {
   const user = useSelector((state) => state.connectedUser);
   const redirectPath = HOME;
   return (
      <Suspense fallback={<CircularProgress />}>
         <Switch>
            <Route path={`${HOME}`} exact component={Home} />
            <Route path={`${ABOUT}`} exact component={About} />
            <Route path={`${RGPD}`} exact component={Rgpd} />
            <Route path={`${TERMS}`} exact component={Terms} />
            <Route path={`${AUTHORIZATION}`} exact>
               {user === false ? <Auth /> : <Redirect to={`${USERS_LIST}`} />}
            </Route>
            <Route
               path={`${AUTHORIZATION_FORGOTTEN_PASSWORD}`}
               exact
               component={ForgottenPassword}
            />
            <Route
               path={`${AUTHORIZATION_RESET_PASSWORD}`}
               exact
               component={ResetPassword}
            />
            <Route path={`${PROFILE_EDIT}`} exact>
               {user === false ? (
                  <Redirect to={`${redirectPath}`} />
               ) : (
                  <ProfileEdit />
               )}
            </Route>
            <Route path={`${USERS_LIST}`} exact>
               {user === false ? (
                  <Redirect to={`${redirectPath}`} />
               ) : (
                  <Users />
               )}
            </Route>
            <Route path={`${USERS_READ}/:id`} exact component={UserDetails}>
               {user === false ? <Redirect to={`${redirectPath}`} /> : null}
            </Route>
            <Route path={`${USERS_CREATE}`} exact>
               {user === false ? (
                  <UserCreate />
               ) : (
                  <Redirect to={`${PROFILE_EDIT}`} />
               )}
            </Route>
            <Route path={`${INVITATIONS_LIST}`} exact>
               {user === false ? (
                  <Redirect to={`${redirectPath}`} />
               ) : (
                  <Invitations />
               )}
            </Route>
            <Route path={`${ACCOUNT}`} exact>
               {user === false ? (
                  <Redirect to={`${redirectPath}`} />
               ) : (
                  <Account />
               )}
            </Route>
            <Route component={UnknownRoute} />
         </Switch>
      </Suspense>
   );
};

export default Routes;
