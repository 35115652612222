import {
   ADD_INVITATION,
   EDIT_INVITATION,
   DELETE_INVITATION,
   FETCH_INVITATIONS,
   INVITATIONS_CLEAN
} from '../actions/types';
import _ from 'lodash';

const invitationReducer = (state = [], action) => {
   switch (action.type) {
      case FETCH_INVITATIONS:
         return { ...state, ..._.mapKeys(action.payload, '_id') };
      case ADD_INVITATION:
         return { ...state, [action.payload._id]: action.payload };
      case EDIT_INVITATION:
         return { ...state, [action.payload._id]: action.payload };
      case INVITATIONS_CLEAN:
         return {};
      case DELETE_INVITATION:
         return _.omit(state, action.payload);
      default:
         return state;
   }
};

export default invitationReducer;
