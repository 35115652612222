import { io } from 'socket.io-client';
import { OPEN_SOCKET, CLOSE_SOCKET } from './types';
import { keys } from '../config/keys.js';

/**
 * Ouvre la connexion socket.io
 * @returns {function(...[*]=)}
 */
export const openSocketConnection = (connectedUserId) => async (dispatch) => {
   try {
      const socket = io(keys.SOCKET_URL, { autoConnect: false });
      // L'user rejoint une roomId = userId pour communication socket simplifiée
      socket.auth = { connectedUserId };
      socket.connect();
      dispatch({ type: OPEN_SOCKET, payload: socket });
   } catch (error) {
      // dispatch({
      //    type: INFO,
      //    payload: {
      //       message: 'Mise à jour impossible',
      //       error: true,
      //       severity: 'error'
      //    }
      // });
   }
};

export const closeSocketConnection = (socket) => async (dispatch) => {
   try {
      socket.disconnect();
      dispatch({ type: CLOSE_SOCKET });
   } catch (error) {
      // dispatch({
      //    type: INFO,
      //    payload: {
      //       message: 'Mise à jour impossible',
      //       error: true,
      //       severity: 'error'
      //    }
      // });
   }
};
