import React from 'react';
//Icônes MUI
// import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import {
   HOME,
   USERS_LIST,
   USERS_READ,
   INVITATIONS_LIST,
   PROFILE_EDIT,
   ACCOUNT
} from '../../routes';

const MenuSignedIn = [
   {
      label: 'Accueil',
      pathname: HOME,
      icon: <HomeIcon />
   },
   {
      label: 'Colocataires',
      pathname: [USERS_LIST, USERS_READ],
      icon: <SearchIcon />
   },
   {
      label: 'Contacts',
      pathname: INVITATIONS_LIST,
      icon: <SendIcon />
   },
   {
      label: 'Profil',
      pathname: PROFILE_EDIT,
      icon: <AccountCircleIcon />
   },
   {
      label: 'Paramètres',
      pathname: ACCOUNT,
      icon: <SettingsIcon />
   }
];

export default MenuSignedIn;
