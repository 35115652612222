import { DELETE_UNREAD, FETCH_UNREAD } from '../actions/types';
import _ from 'lodash';

const unreadReducer = (state = [], action) => {
   switch (action.type) {
      case FETCH_UNREAD:
         return { ...state, ..._.mapKeys(action.payload, '_id') };
      case DELETE_UNREAD:
         return _.omit(state, action.payload);
      default:
         return state;
   }
};

export default unreadReducer;
