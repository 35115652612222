import { INFO, INFO_CLEAN } from '../actions/types';

const infoReducer = (state = null, action) => {
   switch (action.type) {
      case INFO:
         return { ...action.payload };
      case INFO_CLEAN:
         return null;
      default:
         return state;
   }
};

export default infoReducer;
