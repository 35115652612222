import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
// MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
// Maison
import {
   required,
   alphaNumeric,
   email,
   maxLength
} from './helpers/formHelpers/FieldLevelValidationForm';
import { sendContactFormData } from '../actions/userActions';

export default function ContactForm() {
   const [openDialog, setOpenDialog] = useState(false);
   const dispatch = useDispatch();
   const { control, errors, handleSubmit } = useForm({
      mode: 'onSubmit'
   });

   const onSubmit = (data) => {
      dispatch(sendContactFormData(data));
      handleCloseDialog();
   };

   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = () => {
      setOpenDialog(false);
   };

   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

   return (
      <div>
         <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenDialog}
            disableElevation
         >
            Contactez-nous !
         </Button>
         <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            fullScreen={fullScreen}
            PaperComponent={Paper}
         >
            <form onSubmit={handleSubmit(onSubmit)} className="form">
               <DialogTitle id="form-dialog-title">
                  Formulaire de contact
               </DialogTitle>
               <DialogContent>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Controller
                           name="firstName"
                           control={control}
                           defaultValue=""
                           fullWidth
                           rules={{
                              validate: {
                                 requis: (value) => required(value),
                                 alphanumerique: (value) => alphaNumeric(value)
                              }
                           }}
                           as={
                              <TextField
                                 id="firstname-field"
                                 label="Nom, prénom ou pseudo"
                                 autoFocus={true}
                                 required
                                 fullWidth
                                 variant="outlined"
                                 error={!!errors.firstName}
                                 helperText={
                                    errors.firstName
                                       ? errors.firstName.message
                                       : undefined
                                 }
                                 inputProps={{
                                    maxLength: '24'
                                 }}
                              />
                           }
                        />
                        <Controller
                           name="email"
                           control={control}
                           defaultValue=""
                           fullWidth
                           rules={{
                              validate: {
                                 requis: (value) => required(value),
                                 alphanumerique: (value) => email(value)
                              }
                           }}
                           as={
                              <TextField
                                 id="email-field"
                                 label="Votre adresse mail"
                                 fullWidth
                                 required
                                 type="email"
                                 variant="outlined"
                                 error={!!errors.email}
                                 helperText={
                                    errors.email
                                       ? errors.email.message
                                       : undefined
                                 }
                                 inputProps={{
                                    maxLength: '100'
                                 }}
                              />
                           }
                        />
                        <Controller
                           name="message"
                           control={control}
                           defaultValue=""
                           rules={{
                              validate: {
                                 requis: (value) => required(value),
                                 maxLength: (value) => maxLength(600)(value)
                              }
                           }}
                           as={
                              <TextField
                                 required
                                 margin="dense"
                                 id="message-field"
                                 label="Message"
                                 multiline
                                 rows={6}
                                 error={!!errors.message}
                                 helperText={
                                    errors.message
                                       ? errors.message.message
                                       : undefined
                                 }
                                 placeholder="Indiquez votre message ici :)"
                                 variant="outlined"
                                 fullWidth
                                 inputProps={{
                                    maxLength: '601'
                                 }}
                              />
                           }
                        />
                     </Grid>
                  </Grid>
               </DialogContent>
               <DialogActions>
                  <Button
                     onClick={handleCloseDialog}
                     variant={'outlined'}
                     color={'primary'}
                  >
                     Annuler
                  </Button>
                  <Button
                     variant={'contained'}
                     color={'secondary'}
                     type={'submit'}
                     disableElevation
                  >
                     Envoyer
                  </Button>
               </DialogActions>
            </form>
         </Dialog>
      </div>
   );
}
