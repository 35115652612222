import axios from 'axios';
import { DELETE_UNREAD, FETCH_UNREAD, INFO } from './types';

/**
 * Consulte les messages non lus de l'user connecté
 * @returns {function(*): Promise<void>}
 */
export const fetchUnread = () => async (dispatch) => {
   try {
      const response = await axios.get(`/api/unread`);
      dispatch({ type: FETCH_UNREAD, payload: response.data });
   } catch (error) {
      await dispatch({
         type: INFO,
         payload: {
            message: 'Impossible de récupérer les messages non lus',
            error: true,
            severity: 'error'
         }
      });
   }
};

/**
 * Supprimer un message non lu (Quand l'user lit le message)
 * @param fromObjectId L'id de l'invitation (ou de l'annonce, du message chat, etc...)
 * @returns {function(...[*]=)}
 */
export const deleteUnread = (id) => async (dispatch) => {
   try {
      await axios.delete(`/api/unread/${id}`);
      dispatch({ type: DELETE_UNREAD, payload: id });
   } catch (error) {
      dispatch({
         type: INFO,
         payload: {
            message:
               'Impossible de supprimer la notification du message non lu',
            error: true,
            severity: 'error'
         }
      });
   }
};
