import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
//MUI
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
//Composants maison
import MenuSignedIn from './MenuSignedIn';
import MenuVisitor from './MenuVisitor';
import { HOME } from '../../routes';
import CustomDialog from '../helpers/CustomDialog';
import { logOut } from '../../actions/userActions';
import { ReactComponent as LogoText } from '../../styles/images/mescolocataires-logo-text.svg';
import { ReactComponent as Logo } from '../../styles/images/mescolocataires-logo.svg';
import '../../styles/Navigation.scss';
import { INVITATIONS_LIST } from '../../routes';

const useStyles = makeStyles(() => ({
   logo: {
      width: '48px'
   },
   logotext: {
      width: '220px'
   }
}));

const Navigation = (props) => {
   const connectedUser = useSelector((state) => state.connectedUser);
   const connectedUserUnreads = useSelector((state) => state.unread);
   const [menuDrawerMobile, setMenuDrawerMobile] = useState(false);
   const classes = useStyles();

   const dispatch = useDispatch();
   // Y a-t'il des messages non lus
   const hasUnread = () => {
      return !_.isEmpty(connectedUserUnreads);
   };
   const menuIcon = <MenuIcon />;
   // Si messages non lus, on ajoute un badge à l'icône passée en paramètre
   const unreadIcon = (icon) => {
      let showIcon;
      hasUnread()
         ? (showIcon = (
              <Badge color="primary" variant="dot">
                 {icon}
              </Badge>
           ))
         : (showIcon = icon);
      return showIcon;
   };

   let menu;
   connectedUser ? (menu = MenuSignedIn) : (menu = MenuVisitor);

   const mobileMenuOpen = () => {
      setMenuDrawerMobile(true);
   };

   const mobileMenuClose = () => {
      setMenuDrawerMobile(false);
   };

   const userLogOut = async () => {
      await dispatch(logOut());
      mobileMenuClose();
   };

   const dialogTitle = 'Voulez-vous vraiment vous déconnecter ?';
   const dialogDescription =
      'Il faudra vous reconnecter en utilisant votre identifiant et votre mot de passe à votre prochaine visite.';
   const dialogActionCancelText = 'Non, je reste';
   const dialogActionConfirmText = 'Me déconnecter';
   const icon = <PowerSettingsNewIcon />;

   //Retourne la valeur de navigation en fonction du chemin actuel
   function navigateValue(actualPathname, Menu) {
      let tabValue = 0;
      for (const [key, value] of Object.entries(Menu)) {
         //Plusieurs pathname pour un tab
         // (Ex : Connexion / Inscription correspond au chemin USER_CREATE & AUTHENTICATION)
         if (Array.isArray(value.pathname)) {
            if (value.pathname[0] === actualPathname) {
               tabValue = Number(key);
            }
            if (actualPathname.includes(value.pathname[1])) {
               tabValue = Number(key);
            }
         } else {
            if (value.pathname === actualPathname) {
               tabValue = Number(key);
            }
         }
      }
      return tabValue;
   }

   return (
      <AppBar
         position="relative"
         color="transparent"
         variant={'outlined'}
         square
      >
         <Toolbar className="justify-content">
            <Link to={`${HOME}`} className="logo">
               <LogoText className={classes.logotext} />
            </Link>
            <Link to={`${HOME}`} className="logo-mobile">
               <Logo className={classes.logo} />
            </Link>
            {/*Menu Desktop*/}
            <div className="desktop-menu">
               <Tabs
                  value={navigateValue(props.location.pathname, menu)}
                  indicatorColor="secondary"
                  textColor="secondary"
               >
                  {menu.map((item, index) => (
                     <Tab
                        key={index}
                        component={Link}
                        to={
                           Array.isArray(item.pathname)
                              ? item.pathname[0]
                              : item.pathname
                        }
                        label={item.label}
                        icon={
                           item.pathname === INVITATIONS_LIST
                              ? unreadIcon(item.icon)
                              : item.icon
                        }
                     />
                  ))}
               </Tabs>
            </div>
            {connectedUser && (
               <div className="button-logout-desktop">
                  <CustomDialog
                     dialogPrimaryAction={userLogOut}
                     dialogSecondaryAction={mobileMenuClose}
                     dialogTitle={dialogTitle}
                     dialogDescription={dialogDescription}
                     dialogActionCancelText={dialogActionCancelText}
                     dialogActionConfirmText={dialogActionConfirmText}
                     icon={icon}
                  />
               </div>
            )}
            {/*Menu mobile*/}
            <div className="mobile-menu">
               <Drawer
                  anchor="right"
                  open={menuDrawerMobile}
                  variant="temporary"
                  onClose={mobileMenuClose}
               >
                  <div onClick={mobileMenuClose}>
                     <List>
                        {menu.map((item) => (
                           <ListItem
                              component={Link}
                              to={
                                 Array.isArray(item.pathname)
                                    ? item.pathname[0]
                                    : item.pathname
                              }
                              button
                              key={item.label}
                           >
                              <ListItemIcon>
                                 {item.pathname === INVITATIONS_LIST
                                    ? unreadIcon(item.icon)
                                    : item.icon}
                              </ListItemIcon>
                              <ListItemText primary={item.label} />
                           </ListItem>
                        ))}
                     </List>
                  </div>
                  {connectedUser && (
                     <div style={{ textAlign: 'center' }}>
                        <CustomDialog
                           dialogPrimaryAction={userLogOut}
                           dialogSecondaryAction={mobileMenuClose}
                           dialogTitle={dialogTitle}
                           dialogDescription={dialogDescription}
                           dialogActionCancelText={dialogActionCancelText}
                           dialogActionConfirmText={dialogActionConfirmText}
                           icon={icon}
                        />
                     </div>
                  )}
               </Drawer>
            </div>
            <IconButton
               onClick={mobileMenuOpen}
               className="mobile-menu-icon"
               color="inherit"
               aria-label="Menu"
            >
               {hasUnread() ? unreadIcon(menuIcon) : <MenuIcon />}
            </IconButton>
         </Toolbar>
      </AppBar>
   );
};

export default withRouter(Navigation);
