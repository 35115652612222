import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GlobalSnackBar = () => {
   //state local pour gestion de l'affichage de la snackbar
   const [snackbarState, setSnackbar] = useState(false);

   //On accède le store Redux pour savoir si on a une info à afficher
   const info = useSelector((state) => state.info);
   const infoMessage = info ? (info.message ? info.message : null) : null;
   const infoSeverity = info
      ? info.severity
         ? info.severity
         : 'info'
      : 'info';
   useEffect(() => {
      //Si state.info change, on affiche la snackbar
      infoMessage && setSnackbar(true);
   }, [infoMessage]);

   function handleClose(event, reason) {
      if (reason === 'clickaway') {
         return;
      }
      setSnackbar(false);
   }

   return (
      infoMessage && (
         <Snackbar
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left'
            }}
            open={snackbarState}
            autoHideDuration={6000}
            onClose={handleClose}
            action={
               <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
               >
                  <CloseIcon />
               </IconButton>
            }
         >
            <Alert severity={infoSeverity}>
               {_.isString(infoMessage)
                  ? infoMessage
                  : 'Une erreur est survenue'}
            </Alert>
         </Snackbar>
      )
   );
};

export default GlobalSnackBar;
