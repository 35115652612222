//AUTHENTIFICATION
export const FETCH_CONNECTED_USER = 'FETCH_CONNECTED_USER';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
//UTILISATEURS
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const EDIT_USER = 'EDIT_USER';
export const FETCH_FILTERED_USERS = 'FETCH_FILTERED_USERS';
export const USER_SEARCH_CLEAN = 'USER_SEARCH_CLEAN';
//INVITATIONS
export const FETCH_INVITATIONS = 'FETCH_INVITATIONS';
export const ADD_INVITATION = 'ADD_INVITATION';
export const EDIT_INVITATION = 'EDIT_INVITATION';
export const DELETE_INVITATION = 'DELETE_INVITATION';
export const INVITATIONS_CLEAN = 'INVITATIONS_CLEAN';
//SEARCH CRITERIAS USER
export const USER_SEARCH_CRITERIAS = 'USER_SEARCH_CRITERIAS';
//INFOS (Backend To FrontEnd)
export const INFO = 'INFO';
export const INFO_CLEAN = 'ERROR_CLEAN';
//UNREAD
export const FETCH_UNREAD = 'FETCH_UNREAD';
export const DELETE_UNREAD = 'DELETE_UNREAD';
// SOCKET
export const OPEN_SOCKET = 'OPEN_SOCKET';
export const CLOSE_SOCKET = 'CLOSE_SOCKET';
