import _ from 'lodash';

import {
   FETCH_USERS,
   FETCH_FILTERED_USERS,
   USER_SEARCH_CLEAN
} from '../actions/types';

const usersReducer = (state = null, action) => {
   switch (action.type) {
      case FETCH_USERS:
         return { ...state, ..._.mapKeys(action.payload, '_id') };
      case FETCH_FILTERED_USERS:
         return { ...state, ..._.mapKeys(action.payload, '_id') };
      case USER_SEARCH_CLEAN:
         return null;
      default:
         return state;
   }
};

export default usersReducer;
