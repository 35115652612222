import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ABOUT, TERMS, RGPD } from '../routes';

import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
// Maison
import { ReactComponent as LogoText } from '../styles/images/mescolocataires-logo-text.svg';
import MenuSignedIn from '../components/header/MenuSignedIn';
import MenuVisitor from '../components/header/MenuVisitor';
import CustomPaper from './helpers/CustomPaper';
import ContactForm from './ContactForm';

const useStyles = makeStyles(() => ({
   logotext: {
      width: '220px'
   }
}));

export function Footer() {
   const connectedUser = useSelector((state) => state.connectedUser);
   const [menu, setMenu] = useState(MenuVisitor);
   const classes = useStyles();

   useEffect(() => {
      if (connectedUser) setMenu(MenuSignedIn);
      else setMenu(MenuVisitor);
   }, [connectedUser]);

   return (
      <footer>
         <CustomPaper variant={'outlined'} square className={'footer'}>
            <Container>
               <Grid container alignItems="center" spacing={2}>
                  <Grid
                     item
                     xs
                     container
                     direction="column"
                     alignItems="center"
                  >
                     {/* Logo */}
                     <Grid item>
                        <LogoText className={classes.logotext} />
                     </Grid>
                     {/* Navigation */}
                     <Grid item container justifyContent="center">
                        {menu.map((item, index) => (
                           <Grid item key={index}>
                              <Link
                                 to={
                                    Array.isArray(item.pathname)
                                       ? item.pathname[0]
                                       : item.pathname
                                 }
                              >
                                 <Button>{item.label}</Button>
                              </Link>
                           </Grid>
                        ))}
                     </Grid>
                  </Grid>
                  <Grid
                     item
                     xs
                     container
                     direction="column"
                     alignItems="center"
                     spacing={1}
                  >
                     <Grid item xs={8}>
                        <Typography variant="caption">
                           Une question, une remarque, une suggestion ?
                        </Typography>
                     </Grid>
                     <Grid item>
                        <ContactForm />
                     </Grid>
                  </Grid>
               </Grid>
               <Divider />
               <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                     <Link to={`${TERMS}`}>
                        <Button>© 2021 mesColocataires</Button>
                     </Link>
                  </Grid>
                  <Grid item>
                     <Link to={`${ABOUT}`}>
                        <Button>Qui sommes-nous ?</Button>
                     </Link>
                  </Grid>
                  <Grid item>
                     <Link to={`${RGPD}`}>
                        <Button>Données personnelles et vie privée</Button>
                     </Link>
                  </Grid>
               </Grid>
            </Container>
         </CustomPaper>
      </footer>
   );
}
