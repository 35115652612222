import {
   FETCH_CONNECTED_USER,
   EDIT_USER,
   LOG_IN,
   LOG_OUT
} from '../actions/types';

const authReducer = (state = null, action) => {
   switch (action.type) {
      case FETCH_CONNECTED_USER:
         return action.payload ? { ...action.payload } : false;
      case EDIT_USER:
         return action.payload ? { ...action.payload } : false;
      case LOG_IN:
         return action.payload ? { ...action.payload } : false;
      case LOG_OUT:
         return false;
      default:
         return state;
   }
};

export default authReducer;
