import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { HashRouter as Router } from 'react-router-dom';

//Initialisation MUI | Voir : https://material-ui.com/getting-started/usage/
import { theme } from '../styles/themeMUI';
import 'typeface-roboto';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { StylesProvider } from '@material-ui/styles';

//Composants fait maison
import Navigation from './header/Navigation';
import Routes from '../routes/routes';
import '../styles/App.scss';
import { Footer } from './Footer';
import { fetchConnectedUser } from '../actions/userActions';
import { fetchUnread } from '../actions/unreadActions';
import {
   openSocketConnection,
   closeSocketConnection
} from '../actions/socketActions';
import GlobalSnackBar from './GlobalSnackBar';
import ScrollToTop from './helpers/ScrollToTop';

const App = () => {
   const socket = useSelector((state) => state.socket);
   const connectedUser = useSelector((state) => state.connectedUser);
   const dispatch = useDispatch();
   //Permet de vérifier que la personne est connecté
   useEffect(() => {
      const initialisation = async () => {
         let user = await dispatch(fetchConnectedUser());
         // On attend l'user connecté pour chercher ses messages non lus
         if (user) {
            dispatch(fetchUnread());
         }
      };
      initialisation();
   }, [dispatch]);

   // Ouvre la connexion socketIO
   useEffect(() => {
      if (socket === null && connectedUser) {
         dispatch(openSocketConnection(connectedUser._id));
      }
      // Lorsqu'on ferme le site, on nettoye la connexion socketIO
      return () => {
         dispatch(closeSocketConnection());
      };
   }, [dispatch, socket, connectedUser]);

   // Ecoute les messages reçus via socketIO
   useEffect(() => {
      if (socket !== null) {
         socket.on('newUnread', (newUnread) => {
            dispatch(fetchUnread());
         });
         // socket.on('newMessageError', () => {
         //    dispatch(errorFeedback());
         // });
      }
   }, [dispatch, socket]);

   return (
      <div>
         <StylesProvider injectFirst>
            <Router>
               <ScrollToTop />
               <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Navigation />
                  <Container>
                     <Routes />
                  </Container>
                  <Footer />
                  <GlobalSnackBar />
               </ThemeProvider>
            </Router>
         </StylesProvider>
      </div>
   );
};
export default App;
