import React from 'react';

//Icônes MUI
import HomeIcon from '@material-ui/icons/Home';
// import SearchIcon from '@material-ui/icons/Search';
import LockIcon from '@material-ui/icons/Lock';

import { AUTHORIZATION, USERS_CREATE, HOME } from '../../routes';

const MenuVisitor = [
   {
      label: 'Accueil',
      pathname: HOME,
      icon: <HomeIcon />
   },
   {
      label: 'Connexion / Inscription',
      pathname: [USERS_CREATE, AUTHORIZATION],
      icon: <LockIcon />
   }
   // {
   //    label: 'Colocs',
   //    pathname: USERS_LIST,
   //    icon: <SearchIcon />
   // }
];

export default MenuVisitor;
