// HOME
export const HOME = '/';
// USERS
export const USERS_READ = '/users/user';
export const USERS_LIST = '/userSearch/list';
export const USERS_CREATE = '/users/create';
// AUTHORIZATION
export const AUTHORIZATION = '/authorization';
export const AUTHORIZATION_FORGOTTEN_PASSWORD = '/forgotten-password';
export const AUTHORIZATION_RESET_PASSWORD = '/reset/:token';
export const LOG_OUT = '/auth/logout';
// PROFILE
export const PROFILE_READ = '/profile/read';
export const PROFILE_EDIT = '/profile/edit';
// INVITATIONS
export const INVITATIONS_LIST = '/invitations/list';
// FAVORIS
export const FAVORITES = '/favorites';
// ACCOUNT
export const ACCOUNT = '/account';
// ADMIN
export const ADMIN = '/admin';
// Informations légales
export const TERMS = '/terms';
// A propos
export const ABOUT = '/about';
// Politique de confidentialité & données
export const RGPD = '/rgpd';
