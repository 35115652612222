import { createTheme, responsiveFontSizes } from '@material-ui/core';

// https://material-ui.com/customization/typography/#responsive-font-sizes
export const theme = responsiveFontSizes(
   createTheme({
      //https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=448ebd&secondary.color=ff9000&secondary.text.color=000000&primary.text.color=000000
      palette: {
         primary: {
            light: '#74bfec',
            main: '#448ebd',
            dark: '#00618d',
            contrastText: '#fff'
         },
         secondary: {
            light: '#ffb363',
            main: '#ff9000',
            dark: '#c56100',
            contrastText: '#fff'
         },
         background: {
            main: '#fbf2e9'
         }
      },
      typography: {
         fontSize: 12,
         // Use the system font instead of the default Roboto font.
         fontFamily: ['Lato', 'sans-serif'].join(','),

         h1: {
            fontSize: 42
         },
         h2: {
            fontSize: 26
         },
         h3: {
            fontSize: 22
         }
      }
   })
);
