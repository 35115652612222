import React from 'react';
// MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';

const CustomDialog = (props) => {
   const [openDialog, setOpenDialog] = React.useState(false);

   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = () => {
      setOpenDialog(false);
   };

   const handlePrimaryAction = async () => {
      props.dialogPrimaryAction && (await props.dialogPrimaryAction());
   };
   const handleSecondaryAction = async () => {
      props.dialogSecondaryAction && (await props.dialogSecondaryAction());
      handleCloseDialog();
   };

   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
   return (
      <div>
         <Button
            variant="contained"
            disableElevation
            color="secondary"
            onClick={handleOpenDialog}
            startIcon={props.dialogButtonText && props.icon}
            className={props.buttonClassName || ''}
         >
            {props.dialogButtonText ? props.dialogButtonText : props.icon}
         </Button>

         <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperComponent={Paper}
            fullScreen={fullScreen}
         >
            <DialogTitle id="alert-dialog-title">
               {props.dialogTitle}
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {props.dialogDescription}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               {props.dialogActionCancelText && (
                  <Button
                     disableElevation
                     variant="outlined"
                     onClick={handleSecondaryAction}
                     color="primary"
                  >
                     {props.dialogActionCancelText}
                  </Button>
               )}
               {props.dialogActionConfirmText && (
                  <Button
                     disableElevation
                     variant="contained"
                     onClick={handlePrimaryAction}
                     color="secondary"
                     autoFocus
                  >
                     {props.dialogActionConfirmText}
                  </Button>
               )}
            </DialogActions>
         </Dialog>
      </div>
   );
};

export default CustomDialog;
